<template>
	<Main></Main>
</template>

<script>
import Main from './ui/Main.vue';

export default {
	name: 'PublicCouncilNOC',
	components: {
		Main
	}
};
</script>
