// Если нам надо оплучить глобальные данные
// import { getters } from '@/store/store';
// Если нам надо обработать данные используем мутации
// import { mutations } from './mutations';

export default {
	getOSMembers(params) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('OS/GetOSMembers', params)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getOSProtokols(params) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('OS/GetOSProtokols', params)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	deleteOSProtokol(id) {
		return new Promise((resolve, reject) => {
			this.$http
				.delete(`OS/DeleteOSProtokol?id=${id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	addOSProtokol(params) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('OS/AddOSProtokol', params)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	uploadFile(image) {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append('File', image.target.files[0]);
			this.$http
				.post('User/UploadFile', formData, { 'Content-Type': 'multipart/form-data' })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	updateOSProtokol(params) {
		return new Promise((resolve, reject) => {
			this.$http
				.put('OS/UpdateOSProtokol', params)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
