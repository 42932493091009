<template>
	<div class="w-full relative">
		<div class="flex justify-between">
			<h1 class="text-xl h-6 font-extrabold sm:text-2xl">Протоколы ОС по НОК</h1>
			<div class="flex">
				<Button v-if="userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])" text="Добавить протокол" icon="add" @click.native="openPopUp()" class="mr-2" />
				<Button text="Экспорт в Excel" icon="exel" @click.native="upLoadProtocols(params)" />
			</div>
		</div>
		<div class="flex flex-col w-full h-auto mt-4 rounded-lg bg-white filter shadow-default relative">
			<!-- <div class="flex w-full h-auto mt-4 px-7 py-5 rounded-lg bg-white filter shadow-default"> -->
			<div class="relative w-full rounded-lg bg-white">
				<div
					class="t-overflow min-w-full rounded-t-lg overflow-x-auto h-auto bg-white filter"
					:class="{ 'custom-w-table-144': !menu, 'custom-w-table-360': menu }"
					:style="protocols.length > 5 ? 'height: calc(100vh - 316px)' : ''"
				>
					<div class="table w-full" :style="protocols.length > 0 ? 'min-height: 350px' : ''">
						<!-- Заголовки -->
						<div class="table-header-group" :class="{ tr1__sticky: rowsVisible > 9 }">
							<div class="thead table-row content-center">
								<div class="table-cell border-b sm:border-gray-100 sm:relative text-center w-20">
									<span class="my-auto text-gray-400 font-normal justify-between items-center"> П/П </span>
								</div>

								<div v-for="(item, key) in fields" :key="'A' + key" class="table-cell th-content border-b sm:border-gray-100 sm:relative">
									<span
										class="w-full my-auto text-gray-400 cursor-pointer font-normal flex justify-start items-center"
										:class="{ 'text-blue-500 underline': filterBy === key }"
										@click="switchFilterVisibility(key)"
									>
										<p :class="{ 'border-b': params.filters.find((f) => f.fieldName === item.fieldName) }">
											{{ item.Name }}
										</p>
										<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
										<!-- eslint-disable max-len -->
										<svg class="sm:hidden" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M18.1436 28.1027C17.6841 28.1027 17.2245 27.9913 16.8068 27.7546C15.9574 27.2811 15.4421 26.4178 15.4421 25.443V21.154C15.4421 20.8895 15.2332 20.3742 15.0244 20.1097L12.0026 16.9347C11.4317 16.3638 11 15.3612 11 14.5953V12.7433C11 11.1976 12.1697 10 13.6597 10H24.3403C25.8024 10 27 11.1976 27 12.6597V14.4421C27 15.4169 26.443 16.4891 25.886 17.0461L22.3629 20.1654C22.1401 20.3603 21.9034 20.8477 21.9034 21.2515V24.7328C21.9034 25.6101 21.3742 26.5849 20.6641 27.0026L19.564 27.7128C19.1323 27.9774 18.6449 28.1027 18.1436 28.1027ZM13.6597 12.0888C13.3255 12.0888 13.0888 12.3673 13.0888 12.7433V14.5953C13.0888 14.7763 13.2837 15.2498 13.5065 15.4726L16.5979 18.7311C17.0714 19.3299 17.5309 20.2907 17.5309 21.154V25.443C17.5309 25.7215 17.7119 25.8747 17.8233 25.9304C17.9765 26.0139 18.2272 26.0696 18.45 25.9304L19.564 25.2063C19.6754 25.1227 19.8146 24.8442 19.8146 24.705V21.2237C19.8146 20.235 20.302 19.1349 20.9983 18.564L24.4517 15.5004C24.6327 15.3194 24.9112 14.7624 24.9112 14.4143V12.6597C24.9112 12.3534 24.6466 12.0888 24.3403 12.0888H13.6597Z"
												fill="#9193A5"
											/>
										</svg>
										<!-- eslint-enable max-len -->
									</span>
									<!-- Фильтр и сортировка -->
									<FilterComponent
										v-on:change="switchFilterVisibility(null)"
										:position="key === 0 ? 'left' : 'right'"
										:filterOpen="filterOpen"
										:id-filter="key"
										:param="params"
										@query="getData"
										:fields="fields"
										:item="item"
									/>
								</div>
							</div>
						</div>
						<!-- /Заголовки -->

						<!-- Содержимое -->
						<div v-if="protocols" class="tbody table-row-group content-center">
							<div v-for="(item, index) in protocols" :key="item.id" class="table-row sm:border-b item-center sm:border-gray-100 text-gray-500">
								<div class="table-cell border-b border-gray-100 text-center">
									<span v-if="params.currentPage === 0">
										{{ index + 1 }}
									</span>
									<span v-if="params.currentPage > 0">
										{{ params.currentPage * params.pageSize + (index + 1) }}
									</span>
								</div>
								<div class="table-cell border-b border-gray-100">
									<p>{{ item.name }}</p>
								</div>
								<div class="table-cell border-b border-gray-100">
									<p>{{ item.number }}</p>
								</div>
								<div class="table-cell border-b border-gray-100">
									<p>{{ item.protokoldate | moment('DD.MM.YYYY') }}</p>
								</div>
								<div class="table-cell border-b border-gray-100">
									<span class="flex">
										<Icons v-if="userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])" class="mr-2 icon-control" icon-name="pen" @click.native="editProtocol(item)" />
										<Icons @click.native="upLoadProtocol(`https://apiosnok.minzdrav.gov.ru${item.fileurl}`, item.name)" class="mr-2 icon-control" icon-name="upload" />
										<Icons v-if="userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])" class="icon-control" icon-name="trash" @click.native="openDelPopup(item, index)" />
									</span>
								</div>
							</div>
						</div>
					</div>
					<center v-if="!loadingTable && protocols.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
				</div>
				<div class="hidden sm:flex flex-row pt-6 pl-6 pr-20 pb-12 text-gray-400 text-sm">
					<div class="mr-auto">
						<p>Всего записей: {{ totalRows }}</p>
					</div>
					<div class="flex flex-row ml-auto">
						<p>Строк на странице:</p>
						<ul class="flex flex-row">
							<li @click="updateRow(50)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 50 }">50</li>
							<li @click="updateRow(100)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 100 }">100</li>
							<li @click="updateRow(200)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 200 }">200</li>
							<li @click="updateRow(null)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == null }">Все</li>
						</ul>
					</div>
					<div class="flex flex-row ml-24">
						<ul class="flex flex-row">
							<li class="flex ml-4 cursor-pointer arrow" @click="page !== 0 ? navigation('params', page - 1) : ''" />
							<li class="flex">
								<span>Стр</span>
								<b class="ml-2">{{ page + 1 }}</b>
								<span class="ml-2">из</span>
								<b class="ml-2">{{ totalCount }}</b>
							</li>
							<li class="flex ml-2 cursor-pointer arrow" @click="page + 1 < totalCount ? navigation('params', page + 1) : ''" />
						</ul>
					</div>
				</div>
			</div>
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
		<PopUp :open="open" size="441px" :title="popupTitle" @close="closePopup()">
			<div>
				<TextInput label="Наименование протокола*" v-model="protocol.name" :error="error.name" />
				<TextInput label="Номер протокола*" v-model="protocol.number" :error="error.number" />
				<TextInput label="Дата протокола*" type="date" min="2018-01-01" :max="currentDate" v-model="protocol.protokoldate" :error="error.protokoldate" />
				<div class="flex">
					<label class="label" v-if="!filesOpen">
						<input class="hidden" type="file" name="" @change="addFile($event)" />
						<div>
							<span v-if="!error.fileUrl" class="underline text-blue-500 text-bold">Загрузить протокол*</span>
							<span v-else class="underline text-red-400 text-bold">Нажмите сюда что бы загрузить протокол!</span>
						</div>
					</label>
					<p class="underline text-blue-500 text-bold" v-if="filesOpen && protocol.fileUrl">{{ protocol.fileUrl.split('/').slice(-1)[0] }}</p>
					<Icons v-if="filesOpen" class="icon-control ml-2" icon-name="trash" @click.native="delUrl()" />
				</div>
			</div>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" color="white" text="Отмена" @click.native="closePopup()" />
				<Button size="medium" text="Сохранить" @click.native="validation" />
			</div>
		</PopUp>
		<PopUp :open="openDelet" size="441px" title="Удалить протокол" @close="closeDelPopup()">
			<div>
				<div class="label">Вы действительно хотите удалить протокол?</div>
				<div class="number">№ {{ protocol.number }}</div>
			</div>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" color="white" text="Отмена" @click.native="closeDelPopup()" />
				<Button size="medium" text="Удалить" @click.native="delProtocol(protocol.id)" />
			</div>
		</PopUp>
	</div>
</template>

<script>
import PopUp from '@/components/PopUp.vue';
import FilterComponent from '@/components/Filter.vue';
import Button from '@/components/Button.vue';
import TextInput from '@/components/TextInput.vue';
import loadingComponent from '@/components/Loading.vue';
import _ from 'lodash';
import Icons from '@/components/Icons.vue';
// Глобальные данные стора
import { getters, mutations, methods } from '@/store/store';
// Локальные данные стора
import actions from '../store/actions';

export default {
	name: 'TableProtocols',
	components: {
		Icons,
		loadingComponent,
		FilterComponent,
		Button,
		PopUp,
		TextInput
	},
	data() {
		return {
			rowsVisible: 100,
			totalRows: 0,
			loadingTable: false,
			currentDate: null,
			totalCount: 0,
			openDelet: false,
			page: 0,
			open: false,
			filterOpen: null,
			filterBy: null,
			fields: [
				{
					Name: 'Наименование протокола',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '№ протокола',
					fieldName: 'number',
					filterType: 2,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Дата протокола',
					fieldName: 'protokoldate',
					filterType: 3,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Действие',
					fieldName: 'controls',
					filterType: 0,
					filterValueType: 0,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			upadte: false,
			protocols: [],
			protocol: {
				name: '',
				number: '',
				fileUrl: null,
				protokoldate: '',
				regionid: ''
			},
			error: {
				name: null,
				number: null,
				fileUrl: null,
				protokoldate: null
			},
			popupTitle: '',
			filterData: {
				sortBy: '',
				searchValue: ''
			},
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 100,
				currentPage: 0
			},
			index: null,
			loadToExcell: false,
			filesOpen: false
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		...methods,
		delUrl() {
			this.filesOpen = false;
			this.protocol.fileUrl = null;
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		upLoadProtocols(params) {
			this.loadToExcell = true;

			this.$http
				.post('OS/GetOSProtokolsExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Протоколы.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		},
		upLoadProtocol(src, name) {
			this.$http
				.get(src, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let type = _.last(src.split('.'));
					let fileNameAndType = decodeURI(`${name}.${type}`);
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
				});
		},
		switchFilterVisibility(key) {
			this.filterData = {
				sortBy: 'asc',
				searchValue: ''
			};
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		addFile(event) {
			this.uploadFile(event).then((res) => {
				this.protocol.fileUrl = res.data;
				this.filesOpen = true;
			});
		},
		openPopUp() {
			this.update = false;
			this.popupTitle = 'Создать протокол';
			this.open = true;
		},
		resetForm() {
			this.protocol = {
				name: '',
				number: '',
				protokoldate: '',
				fileUrl: null,
				regionid: this.getAuthUser.regionId
			};
			this.error = {
				name: null,
				number: null,
				fileUrl: null,
				protokoldate: null
			};
		},
		openDelPopup(item, index) {
			this.index = index;
			this.openDelet = true;
			this.protocol = item;
		},
		closeDelPopup() {
			this.index = null;
			this.openDelet = false;
			this.resetForm();
		},
		updateRow(rowsVisible) {
			this.rowsVisible = rowsVisible;
			this.params.pageSize = rowsVisible;
			this.getData(this.params, true);
		},
		getData(params, flag) {
			this.loadingTable = true;
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
				this.page = 0;
			}

			this.getOSProtokols(params).then((res) => {
				this.protocols = [];
				res.data.data.map((item) => {
					item.protokoldate = this.$moment(item.protokoldate).format('YYYY-MM-DD');
					this.currentDate = this.$moment().format('YYYY-MM-DD');
					return item;
				});
				this.protocols = res.data.data;
				this.totalRows = res.data.totalRecords;
				this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
				this.loadingTable = false;
			});
		},
		navigation(name, page) {
			this.page = page;
			this[name].currentPage = page;

			this.getData(this[name], false);
		},
		editProtocol(item) {
			Object.assign(this.protocol, item);
			this.popupTitle = 'Редактировать протокол';
			this.open = true;
			this.update = true;
		},
		delProtocol(id) {
			this.deleteOSProtokol(id).then(() => {
				this.closeDelPopup();
				this.protocols.splice(this.index, 1);
			});
		},
		closePopup() {
			this.open = false;
			this.filesOpen = false;
			this.resetForm();
		},
		validation() {
			this.error = {
				name: null,
				number: null,
				fileUrl: null,
				protokoldate: null
			};
			if (!this.protocol.name) {
				this.error.name = 'Введите наименование протокола';
			}
			if (!this.protocol.number && !this.update) {
				this.error.number = 'Введите номер протокола';
			}
			if (!this.update && !this.protocol.fileUrl) {
				this.error.fileUrl = 'Загрузите файл';
			}
			if (!this.protocol.protokoldate) {
				this.error.protokoldate = 'Введите дату протокола';
			}

			let errors = Object.values(this.error).some((item) => {
				return item !== null;
			});
			if (!errors) {
				this.sendForm(this.protocol);
			}
		},
		sendForm(item) {
			if (!this.update) {
				this.addOSProtokol(item).then((result) => {
					let added = {};
					Object.assign(added, result.data);
					added.protokoldate = this.$moment(added.protokoldate).format('YYYY-MM-DD');
					added.fileurl = added.fileUrl;

					this.protocols.unshift(added);
				});
			} else {
				this.updateOSProtokol(item).then((res) => {
					if (res.status === 200) {
						for (let i = 0; i < this.protocols.length; i += 1) {
							if (this.protocols[i].id === res.data.id) {
								res.data.protokoldate = res.data.protokolDate;
								Object.assign(this.protocols[i], res.data);
							}
						}
					}
				});
			}
			this.closePopup();
		}
	},
	mounted() {
		this.params.filters[0].value1 = this.region.id;
		this.protocol.regionid = this.region.id;

		this.getData(this.params, true);
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.tr1__sticky {
	background-color: #f9fafb;
	top: 0;
	position: sticky;
	z-index: 7;
}
.table-cell span {
	margin: auto 0;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-cell span.m-auto {
	margin: auto;
}
</style>
<style>
.select-label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.radio-group {
	margin-bottom: 20px;
}

.multiselect {
	margin-bottom: 20px;
}
.multiselect__tags {
	font-weight: 400;
	font-size: 14px;
	line-height: 50px;
	min-height: 50px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #a8b1ce;
	padding: 0 20px;
	color: #6a6e83;
}
.multiselect__tags-wrap {
	display: flex;
	align-items: center;
	height: 50px;
}
.multiselect__option--highlight:after {
	display: none;
}
.multiselect__select {
	height: 50px;
}
.label {
	align-items: center;
	cursor: pointer;
}
.label:hover .label-text {
	text-decoration: none;
}
.label-text {
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-decoration-line: underline;
	color: #3377ff;
}
.th-content .table-cell,
.table-cell {
	padding: 20px;
	vertical-align: top;
}
.th-content {
	white-space: nowrap;
}

.th-content span {
	font-weight: 600;
	text-decoration: underline;
}

.table-cell a {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-decoration-line: underline;
	white-space: nowrap;
	color: #3377ff;
}

.table-cell a:hover {
	text-decoration: none;
}

.icon-control {
	cursor: pointer;
}

.icon-control:hover rect {
	fill: #3377ff;
}

.t-overflow {
	overflow: auto;
	min-height: 300px;
	max-width: 100vw;
}

li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}
.label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.number {
	font-weight: 700;
	font-size: 16px;
	line-height: 25px;
}
</style>
